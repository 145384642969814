<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="350">
      <template v-slot:activator="{ on, attrs }">
        <div class="navi-link" v-bind="attrs" v-on="on">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>

          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </div>
      </template>
      <v-card>
        <v-card-title class="headline">{{ $t('ACTION.UPDATE') }}</v-card-title>
        <v-card-text>
          <v-text-field
            label="O'lcham nomi"
            outlined
            v-model="getData.name"
          ></v-text-field>
          <v-text-field
            label="Kod"
            outlined
            v-model="getData.code"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close">Bekor qilish</v-btn>
          <v-btn color="green darken-1" text @click="update(obj.id)"
            >Yuborish</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    getData() {
      const data3 = {
        name: this.obj.name,
        code: this.obj.code
      }
      return data3
    }
  },
  methods: {
    close() {
      this.name = ''
      this.code = ''
      this.dialog = false
    },
    update(value) {
      const id = {
        id: value
      }
      const data = {
        name: this.getData.name,
        code: this.getData.code
      }

      this.$store.dispatch('updateMeasurements', { id, data })
      this.name = ''
      this.code = ''
      this.dialog = false
    }
  },
  props: {
    obj: Object
  }
}
</script>

<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
</style>
