<template>
  <div class="row">
    <div class="col-12">
      <!-- <div
        class="salert alert alert-custom alert-white alert-shadow fade show gutter-b alert-light"
      >
        <div class="alert-text">
          <div>
            <p>search</p>
          </div>
        </div>
      </div>-->
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.ENTERPRICE.STORE.MEASUREMENTS') }}
            </h3>
          </div>
          <div class="card-button">
            <div>
              <div>
                <b-button variant="success" id="show-btn" @click="showModal"
                  >{{ $t('MENU.ENTERPRICE.STORE.MEASUREMENTS') }} +</b-button
                >

                <b-modal
                  ref="my-modal"
                  hide-footer
                  title="Oʻlcham registratsiyasi"
                >
                  <div class="d-block">
                    <div class="form-group">
                      <label>Oʻlcham nomi</label>
                      <input
                        type="text"
                        placeholder="Oʻlcham nomi"
                        class="form-control form-control-solid form-control-lg"
                        v-model.trim="$v.firstname.$model"
                        :class="{
                          'is-invalid': $v.firstname.$error,
                          'is-valid': !$v.firstname.$invalid
                        }"
                      />
                      <div class="valid-feedback"></div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.firstname.required"
                          >Oʻlcham nomi toldirilishi shart</span
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Kod</label>
                      <input
                        type="text"
                        placeholder="Oʻlcham nomi"
                        class="form-control form-control-solid form-control-lg"
                        v-model.trim="$v.kodInput.$model"
                        :class="{
                          'is-invalid': $v.kodInput.$error,
                          'is-valid': !$v.kodInput.$invalid
                        }"
                      />
                      <div class="valid-feedback"></div>
                      <div class="invalid-feedback">
                        <span v-if="!$v.kodInput.required"
                          >Kod toldirilishi shart</span
                        >
                        <span v-if="!$v.kodInput.maxLength"
                          >Kod {{ $v.kodInput.$params.maxLength.max }} ta bolish
                          kerak test
                        </span>
                        <span v-if="!$v.kodInput.minLength"
                          >Kod {{ $v.kodInput.$params.minLength.min }} ta bolish
                          kerak
                        </span>
                      </div>
                    </div>

                    <!-- <div class="form-group">
                      <label>Kod</label>

                      <input
                        type="text"
                        v-model="kodInput"
                        placeholder="Kod"
                        class="form-control form-control-solid form-control-lg"
                      />
                    </div> -->
                  </div>
                  <b-button
                    class="mt-3"
                    variant="outline-warning"
                    block
                    @click="hideModal"
                    :disabled="newMeasurementLoading"
                    >Bekor qilish</b-button
                  >
                  <b-button
                    class="mt-2"
                    variant="outline-success"
                    block
                    @click="submit(), toggleModal"
                    :disabled="newMeasurementLoading"
                  >
                    <i v-if="newMeasurementLoading" class="el-icon-loading"></i>
                    Yuborish
                  </b-button>
                </b-modal>
              </div>
            </div>
          </div>
          <router-view />
        </div>

        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="getData"
            :items-per-page="10"
            :page.sync="page"
            hide-default-footer
            single-select
            @click:row="rowClick"
            item-key="id"
            class="elevation-1 row-pointer"
          >
            <template v-slot:item.status="{ item }">
              <div :class="item.status_color" class="status__name">
                {{ item.status }}
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <div style="text-align: right !important">
                <measurementsAction :index="item"></measurementsAction>
              </div>
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="Math.ceil(getData.length / 10)"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters, mapActions } from 'vuex'
import measurementsAction from './measurementsAction'
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  validations: {
    kodInput: { required, maxLength: maxLength(4), minLength: minLength(4) },
    firstname: { required }
  },
  data() {
    return {
      newMeasurementLoading: false,
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      submitStatus: null,
      page: 1,
      firstname: '',
      measurementInput: '',
      name: '',
      kodInput: ''
    }
  },
  computed: {
    ...mapGetters(['getAllMeasurements']),
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.MEASUREMENTS_NAME'),
          value: 'name'
        },
        {
          text: this.$t('TABLE_HEADER.CODE'),
          value: 'code'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    getData() {
      return this.$store.state.requests.measurements
    }
  },
  created() {
    this.$store.dispatch('getMeasurements')
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    ...mapActions(['getMeasurements']),
    ...mapActions({ submitMeasurementsForm: 'createMeasurements' }),
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      } else {
        const data = {
          name: this.firstname,
          code: this.kodInput
        }
        this.newMeasurementLoading = true
        this.submitMeasurementsForm(data)
          .then(() => {
            this.newMeasurementLoading = false
            this.$refs['my-modal'].hide()
          })
          .catch((err) => {
            this.newMeasurementLoading = false
            console.error(err)
          })
      }
    },
    setName(value) {
      this.name = value
      this.$v.name.$touch()
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    }
  },
  components: {
    measurementsAction
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.STORE.TITLE') },

      { title: this.$t('MENU.ENTERPRICE.STORE.MEASUREMENTS') }
    ])
    this.getMeasurements()
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
